import { useMutation, useQuery } from "@apollo/client";
import {
  Actions,
  InputDropdown,
  InputText,
  LoadingOverlay,
  SurfaceForm,
  Text,
  Flex,
} from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import { useList } from "@react-hookz/web";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useState, Fragment } from "react";
import {
  previewFamilyFindingBulkOutreachCampaignPath,
  childRelationshipsDashboardPath,
} from "routes";

import { translationWithRoot } from "@components/T";
import HtmlEditor from "@components/inputs/HtmlEditor";

import CreateBulkOutreachCampaign from "@graphql/mutations/CreateBulkOutreachCampaign.graphql";
import UpdateBulkOutreachCampaign from "@graphql/mutations/UpdateBulkOutreachCampaign.graphql";
import AgencyHumansByRelationshipIdsQuery from "@graphql/queries/AgencyHumansByRelationshipIds.graphql";
import BulkOutreachCampaign from "@graphql/queries/BulkOutreachCampaign.graphql";

import { getEncodedBase64SearchParams } from "@lib/base64SearchParams";
import preventDefault from "@lib/preventDefault";
import useBase64SearchParam from "@lib/react-use/useBase64SearchParam";
import useFeatureFlag from "@lib/useFeatureFlag";

import {
  B64PARAMS,
  FAMILY_FINDING_CONTACT_LOG_STATUS_ATTEMPTED,
  FAMILY_FINDING_CONTACT_LOG_STATUS_SUCCESSFUL,
  FAMILY_FINDING_BULK_OUTREACH_CAMPAIGN_EMAIL,
} from "@root/constants";

import {
  filteredAddresses,
  getPrimaryActiveEmail,
} from "./BulkOutreachCampaignRecipientRow";

const { t } = translationWithRoot(
  "family_finding.bulk_outreach_campaigns.draft"
);

const { T } = translationWithRoot("family_finding.bulk_outreach_campaigns");

const { t: contactLogsEnumT } = translationWithRoot(
  "activerecord.enums.family_finding_contact_log.status",
  {
    escapeJavascriptRoot: true,
  }
);

/**
 * Component for writing or editing a bulk outreach campaign draft.
 *
 * Constitues the main body of the Bulk Outreach Campaign page when
 * not previewing the letters
 */
const BulkOutreachCampaignDraft = ({
  id,
  childId,
  childAgencyHumanId,
  channel,
  replyTo,
}) => {
  const { flag: useShrine } = useFeatureFlag(
    "ff_shrine_email_campaigns_12_2024"
  );

  const [emailAttachments, { push: pushEmailAttachment }] = useList([]);
  const [createBulkOutreachCampaign, { loading: createLoading }] = useMutation(
    CreateBulkOutreachCampaign
  );
  const [updateBulkOutreachCampaign, { loading: updateLoading }] = useMutation(
    UpdateBulkOutreachCampaign
  );
  const relationshipIds = useBase64SearchParam("selectedIds") || [];
  const { data: recipients = { agencyHumansByRelationshipIds: [] } } = useQuery(
    AgencyHumansByRelationshipIdsQuery,
    {
      variables: {
        keystoneAgencyHumanId: childAgencyHumanId,
        relationshipIds: relationshipIds,
      },
    }
  );
  const { data: { bulkOutreachCampaign } = {}, loading: campaignLoading } =
    useQuery(BulkOutreachCampaign, {
      variables: { id },
      skip: !id,
    });
  const { formState, setFormAttribute } = useBintiForm(bulkOutreachCampaign);

  // Set the initial state of formState.familyFindingContactLogStatus to 'attempted' if the channel is email
  if (
    channel === FAMILY_FINDING_BULK_OUTREACH_CAMPAIGN_EMAIL &&
    !formState.familyFindingContactLogStatus
  ) {
    setFormAttribute("familyFindingContactLogStatus")(
      FAMILY_FINDING_CONTACT_LOG_STATUS_ATTEMPTED
    );
  }

  const routeParams = `${B64PARAMS}=${getEncodedBase64SearchParams()}`;
  const contactAgencyHumanIds = recipients.agencyHumansByRelationshipIds.reduce(
    (
      ids,
      {
        agencyHuman: {
          id: ahId,
          addresses,
          emailAddresses,
          childId: ahChildId,
        },
      }
    ) => {
      if (ahChildId) return ids;

      if (channel === FAMILY_FINDING_BULK_OUTREACH_CAMPAIGN_EMAIL) {
        // We'll only send to one email, the primary one
        const primaryEmailAddress = getPrimaryActiveEmail(emailAddresses);

        return primaryEmailAddress ? [...ids, ahId] : ids;
      }

      const filteredAddrs = filteredAddresses(addresses);
      return filteredAddrs.length ? [...ids, ahId] : ids;
    },
    []
  );

  const missingContentTemplate =
    // content of Quill editor is "<p><br></p>" when empty
    formState.contentTemplate === "<p><br></p>";

  const [showContentTemplateError, setShowContentTemplateError] =
    useState(false);

  const missingContactLogStatus = !formState.familyFindingContactLogStatus;
  const missingSubject =
    channel === FAMILY_FINDING_BULK_OUTREACH_CAMPAIGN_EMAIL &&
    !formState.subject;

  const [showContactLogStatusError, setContactLogStatusError] = useState(false);
  const [showSubjectError, setShowSubjectError] = useState(false);

  return (
    <Fragment>
      <Text textColor="emphasis-500" textStyle="emphasis-100">
        {t(`${channel}.step_two`)}
      </Text>
      <LoadingOverlay active={campaignLoading}>
        <SurfaceForm
          title={t(`${channel}.relative_notification`)}
          actions={
            <Actions
              isSubmitting={createLoading || updateLoading}
              primaryDisabled={isEmpty(contactAgencyHumanIds)}
              primaryText={t(`${channel}.preview`)}
              primaryAction={preventDefault(async () => {
                let bulkOutreachCampaignId;

                // Show errors if either required field is missing
                // and skip kicking off a mutation
                if (
                  missingContactLogStatus ||
                  missingContentTemplate ||
                  missingSubject
                ) {
                  if (missingContactLogStatus) {
                    setContactLogStatusError(true);
                  } else {
                    // Clear error if it was shown previously
                    // and now the field is filled
                    setContactLogStatusError(false);
                  }
                  if (missingContentTemplate) {
                    setShowContentTemplateError(true);
                  } else {
                    // Clear error if it was shown previously
                    // and now the field is filled
                    setShowContentTemplateError(false);
                  }
                  if (missingSubject) {
                    setShowSubjectError(true);
                  } else {
                    setShowSubjectError(false);
                  }
                } else if (id) {
                  // If updating an existing bulk outreach campaign
                  ({
                    data: {
                      updateBulkOutreachCampaign: {
                        bulkOutreachCampaign: { id: bulkOutreachCampaignId },
                      },
                    },
                  } = await updateBulkOutreachCampaign({
                    variables: {
                      id,
                      emailAttachments,
                      contentTemplate: formState.contentTemplate,
                      familyFindingContactLogStatus:
                        formState.familyFindingContactLogStatus,
                      contactAgencyHumanIds,
                      subject: formState.subject,
                    },
                  }));
                } else {
                  // If creating a new bulk outreach campaign
                  ({
                    data: {
                      createBulkOutreachCampaign: {
                        bulkOutreachCampaign: { id: bulkOutreachCampaignId },
                      },
                    },
                  } = await createBulkOutreachCampaign({
                    variables: {
                      emailAttachments,
                      childAgencyHumanIds: [childAgencyHumanId],
                      contactAgencyHumanIds,
                      contentTemplate: formState.contentTemplate,
                      familyFindingContactLogStatus:
                        formState.familyFindingContactLogStatus,
                      channel,
                      subject: formState.subject,
                    },
                  }));
                }

                window.location = `${previewFamilyFindingBulkOutreachCampaignPath(
                  { id: bulkOutreachCampaignId }
                )}?${routeParams}`;
              })}
              cancelHref={`${childRelationshipsDashboardPath({
                id: childId,
              })}?${routeParams}`}
            />
          }
        >
          <If
            condition={channel !== FAMILY_FINDING_BULK_OUTREACH_CAMPAIGN_EMAIL}
          >
            <InputDropdown
              required
              hideBlank
              label={t("contact_status")}
              onChange={setFormAttribute("familyFindingContactLogStatus")}
              value={formState.familyFindingContactLogStatus}
              /** We're intentionally leaving "returned" off of the draft view
               * as folks will never know that a letter was returned before it's sent
               */
              values={[
                {
                  label: contactLogsEnumT("successful"),
                  value: FAMILY_FINDING_CONTACT_LOG_STATUS_SUCCESSFUL,
                },
                {
                  label: contactLogsEnumT("attempted"),
                  value: FAMILY_FINDING_CONTACT_LOG_STATUS_ATTEMPTED,
                },
              ]}
              error={showContactLogStatusError ? t("required_error") : null}
            />
          </If>

          <If
            condition={channel === FAMILY_FINDING_BULK_OUTREACH_CAMPAIGN_EMAIL}
          >
            <InputText
              label={t("subject")}
              value={formState.subject}
              onChange={setFormAttribute("subject")}
              maxCharacters={255}
              fullWidth
              required
              error={showSubjectError ? t("required_error") : null}
            />
          </If>

          <If
            condition={channel === FAMILY_FINDING_BULK_OUTREACH_CAMPAIGN_EMAIL}
          >
            <Flex column gap="100">
              <Text textStyle="body-100" textColor="neutral-600">
                {t("reply_to")}: {replyTo}
              </Text>
            </Flex>
          </If>
          <HtmlEditor
            id="bulk-outreach-campaign-content"
            label={t("content")}
            name={t("content")}
            value={formState.contentTemplate}
            onChange={setFormAttribute("contentTemplate")}
            printOnly={channel !== FAMILY_FINDING_BULK_OUTREACH_CAMPAIGN_EMAIL}
            mergeFieldsClass="bulk_outreach_campaign"
            allowBlockQuotes={false}
            pushAttachment={pushEmailAttachment}
            withShrine={useShrine}
            authorizeUpload={{
              resource: "AgencyHuman",
              recordId: childAgencyHumanId,
              action: "manage_family_finding_bulk_outreach_campaign?",
            }}
            fullWidth
            required
          />
          <If condition={showContentTemplateError}>
            <Text textColor="danger-600" textStyle="supporting-50">
              {t("required_error")}
            </Text>
          </If>
          <Text as="p" textStyle="emphasis-100">
            <T t="privacy_reminder_html" />
          </Text>
        </SurfaceForm>
      </LoadingOverlay>
    </Fragment>
  );
};
BulkOutreachCampaignDraft.propTypes = {
  id: PropTypes.string,
  childId: PropTypes.number.isRequired,
  childAgencyHumanId: PropTypes.number.isRequired,
  channel: PropTypes.string.isRequired,
  replyTo: PropTypes.string, // We only expect this when the channel is email
};

export default BulkOutreachCampaignDraft;
