import { useMutation } from "@apollo/client";
import { Alert, UploadButton } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import RequirementStatus from "@components/requirements/RequirementStatus";
import { Td, Tr } from "@components/reusable_ui/Table";
import { UploadContext } from "@components/reusable_ui/useUpload";
import useUploadRow from "@components/reusable_ui/useUploadRow";

import CreateApplicationUploadedRecord from "@graphql/mutations/CreateApplicationUploadedRecord.graphql";

import { policy } from "@lib/graphqlHelpers";
import {
  NOT_APPLICABLE,
  TRACKED_OUTSIDE_OF_BINTI,
} from "@lib/overridableHelpers";
import useFeatureFlag from "@lib/useFeatureFlag";
import useShrine from "@lib/useShrine";

import UploadTypeDocuments from "../application_requirements_shared/UploadTypeDocuments";
import UploadTypeOverride from "../application_requirements_shared/UploadTypeOverride";
import UploadTypeRequirementTitle from "../requirements/UploadTypeRequirementTitle";

const SupportingDocumentsV2RowImplementation = ({
  fulfillment,
  application,
  ffShrineSdv2Uploads042024,
}) => {
  const [createUploadedRecord] = useMutation(CreateApplicationUploadedRecord);
  const [showAlert, setShowAlert] = useState(false);
  const { prepareShrineFiles, ShrineErrorAlert } = useShrine();

  const { requirement, title } = fulfillment;

  const uploadFiles = async files => {
    if (files.length === 0) {
      setShowAlert(true);
      return;
    }

    let fileVariables;

    if (ffShrineSdv2Uploads042024) {
      fileVariables = {
        files: [],
        shrineFiles: await prepareShrineFiles(files),
      };
    } else {
      fileVariables = { files };
    }

    await createUploadedRecord({
      variables: {
        applicationId: application.id,
        uploadTypeSlug: requirement.uploadType.slug,
        userAgencyProfileId: requirement.userAgencyProfile?.id,
        ...fileVariables,
      },
    });
  };

  const { getTrProps, getInputProps, aroundLoader, isLoading } =
    useUploadRow(uploadFiles);

  return (
    <Fragment>
      <Tr {...getTrProps()}>
        <Td>
          <UploadTypeRequirementTitle {...{ requirement, title }} />
        </Td>
        <Td>
          <RequirementStatus
            isFulfilled={fulfillment.isFulfilled}
            progressPercentage={fulfillment.progressPercentage}
          />
        </Td>
        <Td>
          <UploadTypeDocuments
            disabled={isLoading}
            {...{
              holderToken: application.holderToken,
              fulfillment,
              aroundLoader,
            }}
          />
        </Td>
        <Td>
          <UploadTypeOverride
            createOverrideLabel={I18n.t(
              "javascript.components.common.not_applicable"
            )}
            deleteOverrideLabel={I18n.t(
              "javascript.components.common.unmark_na"
            )}
            reason={NOT_APPLICABLE}
            disabled={isLoading}
            {...{ application, fulfillment, aroundLoader }}
          />
        </Td>
        <Td>
          <UploadTypeOverride
            createOverrideLabel={I18n.t(
              "javascript.components.common.received"
            )}
            deleteOverrideLabel={I18n.t(
              "javascript.components.common.unmark_received"
            )}
            reason={TRACKED_OUTSIDE_OF_BINTI}
            disabled={isLoading}
            {...{ application, fulfillment, aroundLoader }}
          />
        </Td>
        <Td>
          {policy(fulfillment).mayCreateUploadedRecord() && (
            <UploadButton
              passedInputProps={getInputProps}
              disabled={isLoading}
            />
          )}
        </Td>
      </Tr>

      <ShrineErrorAlert />
      <Alert
        hidden={!showAlert}
        isAlert={true}
        title={I18n.t("javascript.components.common.alert")}
        onSubmit={() => setShowAlert(false)}
        submitText={I18n.t("attachments.errors.ok")}
      >
        {I18n.t("attachments.errors.unpermitted_format")}
      </Alert>
    </Fragment>
  );
};

SupportingDocumentsV2RowImplementation.propTypes = {
  fulfillment: PropTypes.object.isRequired,
  application: PropTypes.object.isRequired,
  ffShrineSdv2Uploads042024: PropTypes.bool.isRequired,
};

/**
 * Row for an upload type requirement in the legacy SDv2 shim table on the
 * legacy Documents page.
 */
const SupportingDocumentsV2Row = ({ fulfillment, application }) => {
  const { flag: ffShrineSdv2Uploads042024, loading } = useFeatureFlag(
    "ff_shrine_sdv2_uploads_04_2024"
  );
  const uploadProvider = ffShrineSdv2Uploads042024 ? "shrine" : "refile";

  // avoid rendering until the feature flag is loaded.
  // it can cause a race condition if we try uploading when the feature flag is on,
  // but the flag variable is still undefined while loading.
  if (loading) return null;

  // wrap the component in the UploadContext.Provider so that the useUploadRow
  // hook can access the upload provider.
  return (
    <UploadContext.Provider value={{ uploadProvider }}>
      <SupportingDocumentsV2RowImplementation
        fulfillment={fulfillment}
        application={application}
      />
    </UploadContext.Provider>
  );
};

SupportingDocumentsV2Row.propTypes = {
  fulfillment: PropTypes.object.isRequired,
  application: PropTypes.object.isRequired,
};

export default SupportingDocumentsV2Row;
